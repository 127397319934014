/* prevent autozoom on mobile devices upon focusing of inputs
 iOS will zoom in on inputs with fonts smaller than 16px
*/
@media screen and (max-width: 767px) {
  input:focus,
  input:active,
  select:focus,
  select:active {
    font-size: 16px !important;
  }
}

.leaflet-container {
  min-height: 200px;
  height: 100%;
  width: 100% !important;
}

select {
  text-transform: capitalize;
}

summary:hover {
  cursor: pointer;
}

.leaflet-interactive {
  stroke: #2a2a2a;
}

.gmnoprint,
.leaflet-bottom {
  display: none !important;
}

p,
span {
  font-size: 1rem;
}

dl {
  margin: 0.5rem 0 0;
  display: flex;
  flex-flow: row wrap;
}

dt {
  width: 30%;
}
dd {
  margin: 0 0 0.5rem 0;
  flex-grow: 1;
  min-width: 70%;
}
